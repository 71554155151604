import React, { useEffect, useState, useCallback } from "react"; // Přidán useCallback
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Dot,
} from "recharts";
import styles from "../css/ProductsGraph.module.css";
import { formatDate } from "../utils/functions/formatDate";
import { useParams } from "react-router-dom";
import CustomActiveDot from "./CustomActiveDot"; // Ponecháme import
import formatNumber from "../utils/functions/formatNumber";

const ProductsGraph = ({ data, onDayClick }) => {
    const { lng } = useParams();
    const [graphData, setGraphData] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState(null);
    // Flag, který zajistí, že výchozí výběr proběhne jen jednou
    const [initialSelectionDone, setInitialSelectionDone] = useState(false);

    // Zabalíme onDayClick do useCallback, pokud by se předával jako prop často se měnící
    const memoizedOnDayClick = useCallback(onDayClick, []);

    useEffect(() => {
        let newData = [];
        // Ověření a případná konverze dat (pokud nejsou pole)
        if (data && typeof data === 'object' && Object.keys(data).length > 0) {
            if (Array.isArray(data)) {
                newData = [...data];
            } else {
                // Zde přidejte logiku pro konverzi `data` na pole, pokud je to nutné
                console.warn("ProductsGraph: Data prop is not an array. Attempting conversion.");
                // Příklad: newData = Object.values(data).sort(...);
                 try {
                    // Zkusíme převod, pokud je to iterovatelný objekt
                    newData = Array.from(data);
                 } catch (error) {
                    console.error("Failed to convert data to array:", error);
                    newData = [];
                 }
            }
        } else {
             // Pokud data nejsou validní nebo jsou prázdná, resetujeme
             newData = [];
        }


        // Aktualizujeme data grafu
        setGraphData(newData);

        // Pokud máme data, ještě nebyl proveden výchozí výběr A NENÍ již něco vybráno uživatelem
        if (newData.length > 0 && !initialSelectionDone) {
            const lastDataPoint = newData[newData.length - 1];
            setSelectedDataPoint(lastDataPoint); // Vybereme poslední bod
             setInitialSelectionDone(true); // Označíme, že výchozí výběr proběhl
            // Informujeme rodiče o výchozím výběru
            if (memoizedOnDayClick) {
                memoizedOnDayClick(lastDataPoint);
            }
        } else if (newData.length === 0) {
            // Pokud data zmizí, resetujeme výběr i flag
            setSelectedDataPoint(null);
            setInitialSelectionDone(false);
            if (memoizedOnDayClick) {
                 memoizedOnDayClick(null);
            }
        }

    // Závislost na datech a callbacku (memoizovaném)
    // initialSelectionDone není v závislostech, řídí se jen uvnitř efektu
    }, [data, memoizedOnDayClick]);

    const handleClick = (e) => {
         // Jakmile uživatel klikne, považujeme výchozí výběr za "přepsaný"
         // I když klikne mimo, nechceme se vracet k výchozímu poslednímu bodu
         if (!initialSelectionDone && graphData.length > 0) {
              setInitialSelectionDone(true);
         }

        if (e && e.activePayload && e.activePayload.length > 0) {
            const clickedPayload = e.activePayload[0].payload;

            if (selectedDataPoint && selectedDataPoint.date === clickedPayload.date) {
                // Klik na stejný bod -> zrušit výběr
                setSelectedDataPoint(null);
                if (memoizedOnDayClick) memoizedOnDayClick(null);
            } else {
                // Klik na jiný bod -> vybrat nový
                setSelectedDataPoint(clickedPayload);
                if (memoizedOnDayClick) memoizedOnDayClick(clickedPayload);
            }
        } else {
            // Klik mimo body (do grafu) - zde můžeme/nemusíme rušit výběr
            // Pokud chcete zrušit výběr kliknutím mimo:
            // setSelectedDataPoint(null);
            // if (memoizedOnDayClick) memoizedOnDayClick(null);
        }
    };

    // Funkce pro výpočet domény Y osy (beze změny)
    const calculateDomainMax = (dataMax) => {
        if (typeof dataMax !== "number" || dataMax === 0) return 10;
        return Math.ceil(dataMax * 1.2);
    };

    // Vlastní renderer pro základní vzhled bodů (beze změny)
    const renderCustomDot = (props) => {
        const { cx, cy, stroke, payload } = props;

        if (selectedDataPoint && payload.date === selectedDataPoint.date) {
            // Větší bod pro vybraný
            return <Dot cx={cx} cy={cy} r={8} fill={"white"} stroke={stroke} strokeWidth={3} />;
        }
        // Standardní malý bod pro ostatní (nebo null, pokud je nechcete)
        return <Dot cx={cx} cy={cy} r={4} fill="white" stroke={stroke} strokeWidth={3} />;
    };

    return (
        <div className={styles.container}>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={graphData}
                    onClick={handleClick}
                    margin={{ top: 0, right: 50, bottom: 0, left: 0 }}
                    style={{ cursor: "pointer" }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date) => formatDate(lng, date)}
                        interval={"preserveEnd"}
                        tickMargin={20}
                        tickSize={15}
                        angle={-45}
                        height={100}
                    />
                    <YAxis
                        domain={["auto", calculateDomainMax]}
                        tickFormatter={(value) => formatNumber(value, undefined, 0)}
                    />
                    <Tooltip
                        offset={100}
                        cursor={{ strokeWidth: 3, strokeDasharray: 10 }}
                        labelFormatter={(label) => formatDate(lng, label)}
                        formatter={(value, name) => [formatNumber(value, undefined, 0), name]}
                    />

                    {/* Kombinace `dot` pro výběr a `activeDot` pro hover */}
                    <Line
                        strokeWidth={4} type="monotone" name="Aktivní" dataKey="approved_count" stroke="#22E06C" legendType="circle"
                        dot={renderCustomDot} // Vykreslení základních/vybraných bodů
                        activeDot={<CustomActiveDot />} // Vykreslení při hoveru
                    />
                    <Line
                         strokeWidth={4} type="monotone" name="Končící platnost" dataKey="suspended_count" stroke="#ED7200" legendType="circle"
                         dot={renderCustomDot}
                         activeDot={<CustomActiveDot />}
                    />
                    <Line
                        strokeWidth={4} type="monotone" name="Čekající na vyřízení" dataKey="pending_count" stroke="#0000FF" legendType="circle"
                        dot={renderCustomDot}
                        activeDot={<CustomActiveDot />}
                    />
                    <Line
                         strokeWidth={4} type="monotone" name="Zamítnuto" dataKey="rejected_count" stroke="#FF0000" legendType="circle"
                         dot={renderCustomDot}
                         activeDot={<CustomActiveDot />}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProductsGraph;